import React from 'react';
import Helmet from 'react-helmet';
import { alt, getPageTheme } from '../../lib/brand';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import styles from './styles.module.less';

const Accessibility = () => {
   const header = <Header active={[]} theme={getPageTheme()} />;

   return (
      <Layout header={header}>
         <Helmet>
            <title>{alt('Accessibility Statement')}</title>
            <meta name="description" content="Accessibility Statement" />
            <meta name="keywords" content="accessibility statement" />
            <script
               type="text/javascript"
               src="https://bayequity.usablenet.com/pt/accessibilitystatement.js?l=1"
               async></script>
         </Helmet>
         <div className={styles.accessibility} id="a40AccessibilityStatement"></div>
      </Layout>
   );
};

export default Accessibility;
